<template>
  <div class="tabbar_wrap">
    <div class="page_placeholder">
      <router-view />
    </div>
    <keep-alive>
      <Tabbar v-model="active" :active-color="'var(--tarbarActiveColor)' || verConfig.theme"
        inactive-color="var(--tarbarColor)" fixed placeholder safe-area-inset-bottom @change="Change">
        <TabbarItem v-for="(item, index) in verConfig.tabbar.pages" :key="index" :name="index"
          :class="{ special: item.isSpecial }">
          <span>{{ $t(item.text) }}</span>
          <template #icon="props">
            <img v-if="verConfig.tabbar.type === 'image'" :src="props.active ? item.selectedIconPath : item.iconPath"
              alt="" />
            <i class="iconfont" v-else :class="{
              icon_active: props.active,
              [item.selectedIconPath]: props.active && item.selectedIconPath,
              [item.iconPath]: !props.active || !item.selectedIconPath
            }">
            </i>
          </template>
        </TabbarItem>
      </Tabbar>
    </keep-alive>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from "vant";
export default {
  components: {
    Tabbar,
    TabbarItem,
  },
  data: () => ({
    // active: 0,
  }),
  created() { },
  methods: {
    handleClick() { },
    getIcon(item, active) {
      if (active) {
        return item.selectedIconPath || item.iconPath;
      }
      return item.iconPath;
    },
    Change(index) {
      const item = this.verConfig.tabbar.pages[index];
      // if (index == 3) {
      //   return this.$store.dispatch("toService");
      // }
      this.$router.push(`/tabbar/${item.pagePath}`)
    },
  },
  computed: {
    active: {
      get() {
        return this.$store.state.tabbar.active;
      },
      set(newVal) {
        return newVal;
      },
    },
  },
};
</script>

<style lang="less" scoped>
.tabbar_wrap {
  // background: #f5f5f5;
  // background: var(--pageBG);
  background: var(--bg);

  /deep/ .van-tabbar--fixed {
    position: fixed !important;
  }

  /deep/ .van-tabbar {
    max-width: 750px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    background: var(--light);

    &::after {
      border: none;
    }

    .van-tabbar-item--active {
      background: transparent;
    }
  }
}

.iconfont {
  font-size: calc(22rem / 16);
  color: var(--tarbarColor);
}

.icon_active {
  color: var(--tarbarActiveColor);
}

.special {
  margin-top: calc(-20rem / 16);
  border-radius: 50%;
  background: #fff;
  max-width: fit-content;
  padding: calc(6rem / 16);

  .iconfont {
    font-size: calc(50rem / 16);
  }
}
</style>